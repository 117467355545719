.loader {
    position: fixed;
    background: #e9ecef;
    width: 100%;
    height: 100%;
    z-index: 99999;
    opacity: .9;
    align-items: center;
    text-align: center;
}

.loader p {
    width: 100%;
    color: #FFF;
}

.loaderICON {
    position: absolute;
    width: 1vh;
    height: 1vh;
    top: 50%;
    left: 50%;
    margin-top: -3vh;
    margin-left: 0vh;
    perspective: 60vh
}

.loaderICON:before,
.loaderICON:after {
    content: " ";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.loaderICON:before {
    left: -1.33vh;
    background: linear-gradient(135deg, #80bb1a 46%, #80bb1a 100%);
    transform: translateZ(0vh);
    z-index: 1;
    animation: rotation1 1.5s ease-out infinite;
}

.loaderICON:after {
    right: -1.33vh;
    background: linear-gradient(135deg, rgb(175 174 171) 46%, rgb(131 132 125) 100%);
    transform: translateZ(0vh);
    z-index: 1;
    animation: rotation2 1.5s ease-out infinite;
}

@keyframes rotation1 {
    25% {
        left: 0;
        transform: translateZ(-10vh);
    }

    50% {
        left: (20vh/1.5);
        transform: translateZ(0vh);
    }

    75% {
        left: 0;
        transform: translateZ(20vh);
        z-index: 2;
    }
}

@keyframes rotation2 {
    25% {
        right: 0;
        transform: translateZ(20vh);
        z-index: 2;
    }

    50% {
        right: (20vh/1.5);
        transform: translateZ(0vh);
    }

    75% {
        right: 0;
        transform: translateZ(-10vh);
    }
}