:root {
  --primaryColor: #80bb1a;
  --secondaryColor: #888;
  --htmlBackground: #fff;
  --whiteBackground: #ffffff;
  --choiceBackground: #e9f1dd;
  --choiceText: #80bb1a;
  --choiceBorder: #80bb1a;
  --choiceNumberBackground: #d4dec5;
  --choiceNumberText: #80bb1a;
  --buttonBackground: #80bb1a;
  --buttonBorder: #80bb1a;
  --buttonHover: #a5d84c;
  --buttonBorderHover: #a5d84c;
  --buttonActive: #a5d84c;
  --buttonDisabled: #6e716a;
  --buttonColor: #ffffff;
  --buttonColorHover: #ffffff;
  --buttonColorDisabled: #a8a9a6;
  --borderColor: #ccc;
  --buttonInfo: #17a2b8;
  --colorTitle: #3d3d3d;
  --colorDescription: #3d3d3d;
  --colorText: #888;
  --colorGreen: #80bb1a;
  --colorWhite: #ffffff;
  --colorError: #ff0000;
  --colorBrand: #c7c7c7;
  --colorBrandCompany: #80bb1a;
  --colorOpinion: #EEE;
  --colorOpinionHover: #D5D5D5;
  --colorPlaceholder: #888;
  --footerBackground: #FFF;
  --cardImageBackground: #f1f1f1;
  --svgImageFill: #80bb1a;
}

html {
  font-size: 16px;
  padding: 0;
}

@font-face {
  font-family: "GT America Regular";
  src: url("../fonts/GTAmerica-Regular.woff");
  src: url("../fonts/GTAmerica-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'GT America Regular', sans-serif;
  font-size: 1rem;
  line-height: 1.2rem;
  outline: none;
}

#mask {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000;
  width: 100%;
  height: 100%;
  opacity: .4;
  z-index: 9;
  display: none;
}

h2,
p {
  letter-spacing: .9px;
  margin: 0 0 0.9rem !important;
}

::-webkit-scrollbar {
  display: none;
}

.txtCenter {
  text-align: center;
}

.txtRight {
  text-align: right;
}

div[data-show=off] {
  display: none !important;
}

select[data-show=on] {
  display: block !important;
}

.App {
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  box-sizing: border-box;
}

.question {
  width: 100%;
  height: 95vh;
  margin: 0 auto;
  overflow-y: scroll;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0;
  justify-content: space-around;
}

.element {
  width: 100%;
  padding: 1rem;
  border: 1px white;
}

.fullHeight {
  height: 100% !important;
}

.footer {
  width: 100%;
  min-height: 5%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  position: fixed;
  bottom: 0;
  left: 0;
  background: #FFF;
  z-index: 99999;
}

.footer p {
  font-size: .7rem !important;
  letter-spacing: .9px;
  margin: 0 !important;
}

.timer {
  width: 50%;
}

.brand {
  width: 50%;
  text-align: right;
}

.brand span {
  font-size: .7rem;
  letter-spacing: .9px;
  color: #80bb1a;
  font-style: italic;
}

.screenLandscape {
  display: none;
  text-align: center;
}

.screenLandscape h2 {
  font-size: 1.3rem;
  margin-bottom: 1rem;
  width: 50%;
}

.screenLandscape svg {
  width: 15%;
}

.screenLandscape svg path {
  fill: black;
}

.mai-element {
  width: 100vw;
  padding: 0 1rem;
}

.mai-element-cam {
  width: 100vw;
  height: 100vh;
}

.mai-title {
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 0 1rem;
}

.mai-title h2 {
  width: 100%;
  font-size: 1.2rem;
  line-height: 1.5rem;
  letter-spacing: .9px;
  margin: 0 !important;
}

.mai-title h2 span {
  width: 100%;
  font-size: 1.2rem;
}

.mai-description {
  width: 100%;
  height: auto;
  margin: 0.5rem 0;
  overflow: hidden;
  padding: 0 1rem;
}

.mai-description p {
  letter-spacing: .9px;
  margin: 0;
}

.gnt-choice-buttons {
  display: flex;
  align-items: center;
}

.gnt-button-section button {
  min-width: 8rem;
  padding: .8rem 0.5rem;
  font-size: 1.2rem;
  letter-spacing: .9px;
  line-height: 1.2rem;
}

#errorMessage {
  padding: 1rem;
  color: red;
  font-style: italic;
  display: none;
}

@media (min-width: 580px) {
  .mai-element {
    width: 580px;
  }
}

@media (max-height: 505px) and (orientation: landscape) {
  .wrapper {
    display: none;
  }

  .screenLandscape {
    overflow: hidden;
    display: flex;
    width: 100%;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    flex-direction: column;
    color: gray;
    background-color: white;


  }
}

@media (min-width: 993px) {
  html {
    font-size: 24px;
  }

  .element {
    width: 480px;
    margin: 0 auto;
  }
}

@media (max-height: 844px) {
  .mai-camera {
    height: 100%;
  }
}

@media (max-height: 768px) {}