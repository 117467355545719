#error-page {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
}

.error-content {
    width: 100vw;
    text-align: center;
}

.error-content h1 {
    font-size: 4rem;
    color: #000;
    margin-bottom: 1rem;
}

.error-content h1 span {
    color: #20c997;
}