.gnt-icon-section {
    position: relative;
    border: none;
    margin: 2rem 0;
}

.gnt-icon-section svg {
    width: 80%;
    max-width: 210px;
    position: relative;
}