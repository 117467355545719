.messageDevice {
    width: 100%;
}

.messageDevice h2 {
    font-size: 1.5rem;
    text-align: center;
    color:brown;
}

.messageDevice p {
    margin-top:1rem;
    text-align: center;
}