.chid,
.chtx {
	color: var(--choiceText) !important;
}

.mai-button {
	padding: 0.5rem;
}

.mai-button button {
	min-width: 8rem;
	padding: .8rem 0.5rem;
	font-size: 1.2rem;
	letter-spacing: .9px;
	line-height: 1.2rem;
}

.mai-button-cam button {
	min-width: 8rem;
	padding: .8rem 0.5rem;
	font-size: 1.2rem;
	letter-spacing: .9px;
	line-height: 1.2rem;
}

.btn-success {
	background: var(--buttonBackground) !important;
	border-color: var(--buttonBorder) !important;
	color: var(--buttonColor);
}

.btn-success:hover {
	color: var(--buttonColorHover);
	background-color: var(--buttonHover) !important;
	border-color: var(--buttonHover) !important;
}

.btn-success:onclick {
	color: var(--buttonColorHover);
	background-color: var(--buttonHover) !important;
	border-color: var(--buttonHover) !important;
}

.btn-success.focus,
.btn-success:focus {
	box-shadow: none;
	border-color: var(--buttonHover) !important;
}

.btn-success.disabled,
.btn-success:disabled {
	color: var(--buttonColorDisabled) !important;
	background-color: var(--buttonDisabled) !important;
	border-color: var(--buttonDisabled) !important;
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show>.btn-success.dropdown-toggle {
	color: var(--buttonColor);
	background-color: var(--buttonHover) !important;
	border-color: var(--buttonHover) !important;
}

.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show>.btn-success.dropdown-toggle:focus {
	box-shadow: none;
}

.cam label {
	margin: 0 auto;
	display: block;
	padding: .5rem;
	cursor: pointer;
	width: 9.5rem;
	font-size: 1.2rem;
	text-align: center;
	letter-spacing: .9px;
}

.cam input {
	display: none;
}


.btn-info {
	background: var(--buttonInfo) !important;
	border-color: var(--buttonInfo) !important;
	color: var(--buttonColor);
}

.btn-info:hover {
	color: var(--buttonColorHover);
	background-color: var(--buttonInfo) !important;
	border-color: var(--buttonInfo) !important;
}

.btn-info:onclick {
	color: var(--buttonColorHover);
	background-color: var(--buttonInfo) !important;
	border-color: var(--buttonInfo) !important;
}

.btn-info.focus,
.btn-info:focus {
	box-shadow: none;
	border-color: var(--buttonInfo) !important;
}

.btn-info.disabled,
.btn-info:disabled {
	color: var(--buttonColorDisabled) !important;
	background-color: var(--buttonDisabled) !important;
	border-color: var(--buttonDisabled) !important;
}

.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show>.btn-info.dropdown-toggle {
	color: var(--buttonColor);
	background-color: var(--buttonInfo) !important;
	border-color: var(--buttonInfo) !important;
}

.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.show>.btn-info.dropdown-toggle:focus {
	box-shadow: none;
}